// File app.js

// Require jquery, fixing error jquery/$ not defined
window.$ = window.jQuery = require('jquery');
require('slick-carousel');

// Slick Slider settings
$('#sliderAlbumDetail').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  centerMode: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$('#banner-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  centerMode: true,
  variableWidth: true,
  focusOnSelect: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
        breakpoint: 960,
        settings: {
          centerMode: false,
          variableWidth: false
        }
    }
  ]
});

$('#featured-events').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  centerMode: false,
  focusOnSelect: true,
  autoplay: true,
  autoplaySpeed: 2000
});

$('#trending-events').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  centerMode: false,
  focusOnSelect: true,
  autoplay: true,
  autoplaySpeed: 2500
});